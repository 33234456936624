<!-- @format -->

<template>
    <!-- <div class="login-wrapper container" style="margin-left:auto;margin-right:auto;max-width:600px"> -->
    <div class="login-wrapper container">
        <div class="login_card row">
            <form id="yodalLoginWidgetForm" class="col" action="">
                <div class="row form-group">
                    <!-- leaving out icons for the moment, vue/yarn/webpack is already complaining about asset/entrypoint size -->
                    <!-- <i class="fa fa-envelope login_custom_icon"></i> -->
                    <input
                        class="col form-control yodal-rebrandable yodal-login-rebrandable yodal-dont-hide border-color-focus-primary"
                        type="email"
                        id="email"
                        name="email"
                        placeholder="E-mail"
                        v-model="email"
                    />
                </div>
                <div class="row form-group">
                    <!-- leaving out icons for the moment, vue/yarn/webpack is already complaining about asset/entrypoint size -->
                    <!-- <i class="fa fa-asterisk login_custom_icon"></i> -->
                    <input
                        class="col form-control yodal-rebrandable yodal-login-rebrandable yodal-dont-hide border-color-focus-primary"
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Password"
                        v-model="password"
                    />
                </div>
                <div class="row login_links">
                    <div class="col text-right">
                        <a href="https://app.yodal.com.au/advisers/password/new">Forgot password?</a>
                    </div>
                </div>
                <div id="yodalLoginErrors" style="display:none;" class="alert alert-danger"></div>

                <div class="row login_buttons">
                    <div class="col text-right">
                        <button
                            type="button"
                            v-on:click="login"
                            class="yodal-rebrandable yodal-login-rebrandable background-primary"
                        >
                            Sign In
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'YodalLogin',
    props: ['account', 'apihost'],
    data: function() {
        return {
            email: '',
            password: '',
        }
    },
    methods: {
        boot: function() {
            const accountSettingsPromise = this.getAccountSettings()
            accountSettingsPromise.then(
                (settings) => {
                    this.applyBranding(settings)
                    // below: solution for rebrandable elements remaining hidden upon failure to get settings
                },
                (error) => {
                    // this error is only being logged to stop Vue complaining about 'error' being unused
                    console.log(
                        `Could not get branding for this account, applying default branding. Error:\n\t`,
                        error
                    )
                    this.applyBranding()
                }
            )
        },
        getApiRoute: function() {
            return this.apihost || 'https://app.yodal.com.au'
        },
        login: function() {
            const data = {
                email: this.email,
                password: this.password,
                account_id: this.account,
            }
            const url = `${this.getApiRoute()}/api/widgets/auth`

            const response = fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            response.then((data) => {
                data.json().then((resp) => {
                    if (resp.redirect_url) {
                        window.location.href = resp.redirect_url
                    } else {
                        this.showLoginErrors(resp)
                    }
                })
            })
        },
        getAccountSettings: function() {
            var url = `${this.getApiRoute()}/api/widgets/account_settings/${this.account}`
            const response = fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            return response.then(
                (data) => {
                    return data.json()
                },
                (error) => {
                    console.log(`Error getting account settings: ${error}`)
                }
            )
        },
        applyBranding: function(settings) {
            // set a default config in case the settings don't come in.
            let rebrandConfig = {
                primary_colour: '#00adbc',
            }
            try {
                rebrandConfig = settings.account_settings.feature_config.rebrand
            } catch (err) {
                // so far all we need is a primary colour.
                rebrandConfig.primary_colour = '#00adbc'
            }
            const rebrandables = this.$el.getElementsByClassName('yodal-login-rebrandable')
            for (let el of rebrandables) {
                if (el.className.includes('background-primary')) {
                    el.style.backgroundColor = rebrandConfig.primary_colour
                }
                if (el.className.includes('color-primary')) {
                    el.style.color = rebrandConfig.primary_colour
                }
                // override bootstrap's form input styling
                if (el.className.includes('border-color-focus-primary')) {
                    el.addEventListener('focus', function() {
                        el.style.borderColor = rebrandConfig.primary_colour
                    })
                    // apparently blur is the opposite of focus, so here we remove focus styling for form input fields after leaving focus
                    el.addEventListener('blur', function() {
                        el.style.borderColor = ''
                    })
                }
                // rebrandables are 'hidden' by default, to prevent janky color switches from default to custom.
                // having applied correct or default colouring in either case, we now show them.
                el.style.opacity = 1
            }
        },
        showLoginErrors: function(data) {
            var el = this.$el.querySelector('#yodalLoginErrors')
            el.innerHTML = data.error
            el.style.display = ''
        },
    },
    created: function() {
        this.boot()
    },
}
</script>

<style scoped lang="scss">
.login-wrapper {
    &/deep/ {
        @import '~bootstrap/dist/css/bootstrap.min';
        // some bootstrap magic is not applied unless we import its .css explicitly as well for some reason
        @import '~bootstrap/dist/css/bootstrap.min.css';
    }
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    font-family: apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji';
}
.yodal-rebrandable:not(.yodal-dont-hide),
.yodal-hidden-ancestor {
    /* hide rebrandables until we know what branding to apply */
    opacity: 0;
    transition: opacity 300ms;
}

.login_title {
    font-size: 24px;
    color: #fff;
    margin-bottom: 17px;
    text-align: center;
}

.login_card {
    text-align: center;
    margin-bottom: 20px;
    padding: 30px;
    background-color: #fff;
    border-radius: 4px;
    .form-group input:focus {
        box-shadow: none;
        border-color: #00adbc;
    }
}
 
.login_custom_icon {
    line-height: 2;
    float: left;
    color: grey;
    padding: 5px;
    font-size: 14.5px;
    background-color: #fff;
}

.login_links {
    text-align: right;
    margin-top: -5px;
    margin-bottom: 20px;
    a {
        font-size: 16px;
        color: #333;
        text-decoration: underline;
        &:hover {
            color: #666;
            text-decoration: none;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
        }
    }
}

.login_buttons {
    button {
        max-width: 100%;
        display: inline-block;
        padding: 10px 70px;
        background-color: #00adbc;
        color: #fff;
        font-size: 14px;
        border: 0;
        border-radius: 30px;
        &:hover {
            opacity: 0.7;
            cursor: pointer;
            box-shadow: 0 0 5px grey;
            transition: all 0.3s;
        }
    }
}

.alert {
    font-size: 16px;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}
.alert-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}
.alert-info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}
.alert-warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
}
.alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}
</style>
